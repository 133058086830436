.p-bg-black {
  background-color: var(--secondary);
}

.p-bg-grey {
  background-color: var(--gray-strong);
}

.p-bg-primary {
  background-color: var(--primary);
}
