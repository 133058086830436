.The-Boxy {
  align-items: center;
  text-align: center;
}

.ImgContainer {
  display: flex; /* Usamos flexbox para centrar vertical y horizontalmente */
  justify-content: center; /* Centramos horizontalmente */
  align-items: center; /* Centramos verticalmente */
  width: 100%;
  height: auto;
}

.KeImage {
  width: 20%;
}

.linea-horizontal {
  border-top: 2px solid #000; /* Grosor y color de la línea */
  position: relative; /* Necesario para posicionar el texto */
}

/* Estilo para el texto interrumpiendo la línea */
.texto-interrumpido {
  position: absolute; /* Permite posicionar el texto */
  background-color: #fff; /* Fondo del texto para cubrir la línea */
  padding: 0 10px; /* Espaciado del texto */
  top: -10px; /* Ajusta la posición vertical del texto */
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--white);
  margin-right: 19vw;
  margin-left: 19vw;
  margin-bottom: 5vh;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--text-secondary);
}

.separator:not(:empty)::before {
  margin-right: 1em;
}

.separator:not(:empty)::after {
  margin-left: 1em;
}

.GridContainer {
  display: flex;
  margin-left: 15vw;
  margin-right: 15vw;
  text-align: center;
  justify-content: center;
  column-gap: 4vw;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan */
  padding-bottom: 5vh;
}

.columna {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .GridContainer {
    column-gap: 17vw;
  }
  .columna:last-child {
    width: 100%; /* Ocupa todo el ancho disponible */
    margin-top: 10px; /* Agrega espacio entre el último elemento y los anteriores */
  }
}

.image {
  width: 20rem;
}
