.loader {
  position: relative;
  height: 50vh; /* Ocupa toda la altura de la ventana */
  overflow: hidden; /* Evita que se vea el logo antes de la animación */
}

.logo {
  position: absolute;
  top: -60%;
  left: 50%;
  transform: translateX(-20%);
  -webkit-transform: translateX(-20%);
  -moz-transform: translateX(-20%);
  -ms-transform: translateX(-20%);
  -o-transform: translateX(-20%);
}

.logo.animate {
  animation: drop 10s forwards;
  -webkit-animation: drop 10s forwards;
}

@keyframes drop {
  0% {
    top: -110%;
    transform: translateX(-20%);
    -webkit-transform: translateX(-20%);
    -moz-transform: translateX(-20%);
    -ms-transform: translateX(-20%);
    -o-transform: translateX(-20%);
  }
  30% {
    top: 30%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  90% {
    top: 30%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }
  100% {
    top: 30%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }

  /*
  To turn back, we have to use: 
   top: -110%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    */
}

.bird {
  background-image: url("/src/assets/bird-cells-new.svg");
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
  animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
  animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bird--one {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.bird--two {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.bird--three {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.bird--four {
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
  -webkit-animation-name: fly-right-one;
  animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bird-container--one {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.bird-container--two {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.bird-container--three {
  -webkit-animation-duration: 14.6s;
  animation-duration: 14.6s;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}
.bird-container--four {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 10.25s;
  animation-delay: 10.25s;
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}
@-webkit-keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }
  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }
  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }
  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }
  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}
@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }
  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }
  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }
  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }
  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}
@-webkit-keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }
  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }
  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }
  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }
  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}
@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }
  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }
  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }
  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }
  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}

.animated-cham {
  opacity: 0.6;
  animation: moves 40s infinite;
  width: fit-content;
  transform: translateX(-100%);
  transform: translateY(23%);
  scale: 2;
  margin-bottom: -10vw;
}

@keyframes moves {
  0% {
  }

  100% {
    transform: translateX(200vw);
  }
}

#animals-1 {
  display: hidden;
}

.patostyle {
  scale: 0.7;
  transform: translate(0, -50%);
  animation: champ 2s forwards;
}

@keyframes champ {
  0% {
    transform: translate(0, 100%);
    opacity: 0;
  }

  100% {
    transform: translate(0, 20%);
    opacity: 1;
  }
}
