.informative {
  width: 68%;
  justify-content: center;
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.iconBox {
  margin-top: 0rem;
  margin-bottom: 3rem;
  text-align: center;
}

.wrapper {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.infbuttonok {
  text-align: center;
  margin-bottom: 3.5rem;
}
