.countdown-timer {
  width: 100vw;
  max-width: 100%;
  height: 20vw;
  font-size: 9vw;
  font-family: monospace;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.countdown-timer span {
  margin-top: 3%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 5%;
}

.countdown-timer-text {
  font-size: 2vw;
}
