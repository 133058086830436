.error-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-size: 24px;
  justify-content: center;
  text-align: center;
}
