.faq-container {
  padding-left: 10%;
  padding-right: 10%;
  min-height: 100vh;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-lg-4,
.col-md-6,
.col-sm-12 {
  margin-bottom: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 998px) {
  .col-lg-4,
  .col-md-6,
  .col-sm-12 {
    flex-basis: 100%;
  }
}
