.team-member-name {
  font-size: 1.3rem;
  color: var(--text-secondary);
  word-wrap: break-word;
}

.team-member-image {
  border-radius: 100%;
  height: 9rem;
  width: 9rem;
  margin: auto;
}

.same-size-icon {
  font-size: 128px; /* Tamaño de la fuente del icono */
  line-height: 128px; /* Altura de la línea */
}

.team-modal-no-border {
  border-width: 0px !important;
}

.team-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cards {
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.containerinf {
  display: flex;
  flex-wrap: wrap; /* Permite que las tarjetas se envuelvan a la siguiente línea */
  align-items: center; /* Centra horizontalmente las tarjetas */
  justify-content: center; /* Centra verticalmente las tarjetas */
  gap: 20px; /* Espacio entre las tarjetas */
  /* Otros estilos para el contenedor */
}

.Alineador {
  display: flex;
  width: 100%;
  justify-content: center;
}

.smallCard {
  height: 100%;
  width: 200px;
  justify-content: center;
}

@media (max-width: 1400px) {
  .Alineador {
    margin: auto auto;
  }
  .containerinf {
    max-width: 100%;
  }

  .team-button {
    margin-bottom: 0rem;
    margin-right: 0rem;
  }
}

@media (max-width: 768px) {
  .team-member-image {
    width: 4rem;
    height: 4rem;
  }

  .team-button {
    scale: 0.8;
    margin: 0;
  }

  .kick-button {
    scale: 0.8;
  }
}

.contss {
  display: flex;
  justify-content: space-around;
  /* justify-content: center; */
  align-items: center;
}
