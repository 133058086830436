.faq-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s;
  margin: 5px;
  text-align: left;
}

.question {
  font-size: 22px;
  font-weight: bold;
}

.answer {
  font-size: 14px;
}

.faq-card:hover {
  transform: scale(1.1);
}
