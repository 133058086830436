.imgbox {
  width: 50%;
  margin: auto auto;
  padding-bottom: 2rem;
}

.pendentAcceptPC {
  width: 90%;
  margin-top: 10%;
  margin-bottom: 10%;
}

.lolospace {
  padding: 3vw;
}
.responsive-amongButton {
  width: 20%;
}
@media (max-width: 768px) {
  .lolospace {
    padding: 15vw;
  }
  .row {
    text-align: center;
  }
  .responsive-amongButton {
    width: 100%;
    margin-left: auto;
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
