/* ContactPage.css */
.container-all {
  min-height: 100vh;
}

.contact-container {
  display: flex;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;
}

.logo-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-logo {
  font-size: 22px;
  margin-bottom: 30px;
  text-align: center;
}

.logo {
  width: 500px;
  height: auto;
  margin-bottom: 20px;
}

.social-logos {
  display: flex;
  margin-top: 20px;
}

.social-logo {
  width: 50px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.form-container-contacte {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.form-contacte {
  width: 100%;
  max-width: 70%;
}

label {
  font-size: 16px;
  margin-bottom: 8px;
}

input,
textarea {
  padding: 10px;
  font-size: 12px;
  border: 1px solid var(--text-secondary);
  border-radius: 4px;
  margin-bottom: 16px;
  width: 100%;
}

.button-submit-container {
  display: flex;
  justify-content: center;
}

.formik-field {
  margin-bottom: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .logo-container {
    margin-bottom: 20px;
  }

  .logo {
    width: 70%;
  }

  .title-logo {
    font-size: 14px;
    margin: 20px;
  }

  .social-logos {
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }

  .social-logo {
    width: 40px;
  }

  .form-container-contacte {
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    margin: 10px;
  }

  .form-container-contacte {
    margin-top: 40px;
  }
}

.valerr {
  color: var(--text-secondary);
}

.iconBox {
  margin-top: 10rem;
  margin-bottom: 3rem;
  text-align: center;
}
