.DefaultPfpFill {
  color: #444;
}

.profilePfpStyle {
  border-radius: 100%;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  aspect-ratio: 1/1;
}
