.valerre {
  width: 100%;
  min-height: 100vh;
  justify-content: center;
}

.valerre .loader {
  display: flex;
  justify-content: center;
}
.valerre .loader:before {
  content: "";
  display: block;
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;

  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 15%;

  margin: 5% auto;
}

.loader-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  -webkit-animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  animation:
    dash 1.5s ease-in-out infinite,
    color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: var(--primary);
  }
  40% {
    stroke: var(--primary);
  }
  66% {
    stroke: var(--primary);
  }
  80%,
  90% {
    stroke: var(--primary);
  }
}
@keyframes color {
  0% {
    stroke: var(--primary);
  }
  40% {
    stroke: var(--primary);
  }
  66% {
    stroke: var(--primary);
  }
  80%,
  90% {
    stroke: var(--primary);
  }
}

@keyframes ellipsis {
  0%,
  20% {
    content: ".";
  }
  40% {
    content: "..";
  }
  60% {
    content: "...";
  }
  80%,
  100% {
    content: "   ";
  }
}

.ellipsis-dots::after {
  content: "";
  display: inline-block;
  width: 3em; /* Ajusta según sea necesario */
  animation: ellipsis 4s infinite steps(4);
}
