.calendar-content {
  color: var(--text-secondary);
}

/*https://blog.loprimaryket.com/react-calendar-tutorial-build-customize-calendar/*/
/*https://dev.to/fitzgeraldkd/react-calendar-with-custom-styles-30c9*/

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: var(--primary);
  border: 0px solid;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 5px;
  color: var(--white);
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 3px;
  border: 0;
  background-color: var(--primary-strong);
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: var(--white);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  margin-left: 10px;
  margin-right: 10px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.react-calendar__tile {
  max-width: initial !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.7;
  color: var(--gray);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: var(--primary-strong);
  text-align: center;
  line-height: 16px;
  color: var(--black);
  border-radius: 5px;
}
.react-calendar__tile:disabled {
  background-color: var(--white);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--gray-light2);
}
.react-calendar__tile--now {
  background: var(--primary-strong3);
  color: var(--black);
  font-weight: bold;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--white);
  color: var(--primary-strong);
  border-radius: 5px;
}
.react-calendar__tile--hasActive {
  background: var(--primary-strong2) !important;
  color: var(--primary);
  font-weight: bold;
  border-radius: 5px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--white);
  color: var(--primary);
  font-weight: bold;
  border-radius: 5px;
}
.react-calendar__tile--active {
  background: var(--primary-strong2);
  color: var(--primary);
  font-weight: bold;
  border-radius: 5px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--white);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--gray);
}

.react-calendar__navigation__label {
  color: var(--white);
  font-size: 1.5em;
  padding-top: 4px;
  line-height: 1;
}

.react-calendar__navigation__arrow {
  color: var(--white);
}

.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation button:disabled {
  background-color: var(--primary);
}

.claseGhosting {
  position: relative;
  top: -13.2vh;
}
